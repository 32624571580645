import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Box, Themed } from "theme-ui"
import parse, { domToReact } from "html-react-parser"

const PostWp = ({ data }) => {

  const post = data.allWpPost.nodes[0]

  const changeAbsolutePathsToRelativePaths = {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href) {
        const link = attribs.href.replace('http://fundacionbibliotecasocial.local/','/');
        return <Link to={link}>{domToReact(children)}</Link>;
      }
    }
  }

  return (
    <Layout>
      <Box
        sx={{
          h1: {
            marginBottom: 0,
            ":after": {
              content: `""`,
              display: `block`,
              width: `10%`,
              height: `1px`,
              my: 2,
              backgroundColor: `gray.1`
            },
          },
          ".date": {
            fontSize: 0,
          }
        }}
      >
        <Themed.h1
          dangerouslySetInnerHTML={{ __html: post.title}}
        />
        <Themed.p className="date">{post.date}</Themed.p>
      </Box>
      <Box
        sx={{
          variant: 'styles',
          ".wp-block-embed": {
            position: `relative`,
            display: `block`,
            height: 0,
            padding: 0,
            overflow: `hidden`,
            "iframe, embed, object": {
              position: `absolute`,
              top: 0,
              left: 0,
              bottom: 0,
              height: `100%`,
              width: `100%`,
              border: 0,
            },
          },
          ".wp-embed-aspect-16-9": {
            paddingBottom: `56.25%`,
          }
        }}
      >
      {parse(post.content, changeAbsolutePathsToRelativePaths)}
      </Box>
    </Layout>
  )

}

export default PostWp

export const pageQuery = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        date(formatString: "DD-MM-YYYY")
      }
    }
  }
`
